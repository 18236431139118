import { DOCUMENT } from '@angular/common';
import {
    Inject,
    Injectable,
} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class FaviconUtilsService {
    constructor(@Inject(DOCUMENT) private document: Document) {
    }

    public setFavicon(faviconUrl: string | undefined) {
        if (faviconUrl) {
            this.document.head.appendChild(this.getFaviconElement(faviconUrl));
        }
    }

    private getFaviconElement(faviconPath:string): Node {
        const faviconLinkElement = this.document.createElement('link');
        faviconLinkElement.setAttribute('rel', 'icon');
        faviconLinkElement.setAttribute('type', 'image/x-icon');
        faviconLinkElement.setAttribute('href', faviconPath);
        faviconLinkElement.setAttribute('onerror', "this.onerror=null;this.src='./favicon.ico'");
        return faviconLinkElement;
    }
}
