import { NgModule } from '@angular/core';
import {
    provideTransloco,
    TranslocoModule,
} from '@ngneat/transloco';
import { provideTranslocoLocale } from '@ngneat/transloco-locale';
import { provideTranslocoMessageformat } from '@ngneat/transloco-messageformat';

import { environment } from '~/environments/environment';

import { TranslocoHttpLoader } from './transloco-loader';

@NgModule({
    exports: [TranslocoModule],
    providers: [
        provideTransloco({
            config: {
                availableLangs: ['en', 'fr'],
                defaultLang: 'en',
                fallbackLang: 'en',
                missingHandler: {
                    useFallbackTranslation: true,
                },
                // Remove this option if your application doesn't support changing language in runtime.
                reRenderOnLangChange: true,
                prodMode: environment.production,
            },
            loader: TranslocoHttpLoader,
        }),
        provideTranslocoLocale(),
        provideTranslocoMessageformat(),
    ],
})
export class TranslocoRootModule {}
