@if (isChallengeRequired) {
    <eb-turnstile (resolved)="refreshChallengeToken($event)" />
}

@if (!isChallengeRequired || (authenticationFacade.challengeToken$ | async)) {
    <router-outlet />
}

@if (isLoading) {
    <div class="app-loader">
        <div class="app-loader__spinner">
            <div class="app-loader__spinner-item"></div>
            <div class="app-loader__spinner-item"></div>
            <div class="app-loader__spinner-item"></div>
        </div>
    </div>
}
