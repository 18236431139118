import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    UntilDestroy,
    untilDestroyed,
} from '@ngneat/until-destroy';
import {
    Actions,
    ofActionSuccessful,
} from '@ngxs/store';
import { map } from 'rxjs';

import { TURNSTILE_SOURCE } from '~/app/core/constants/turnstile-script-source.constants';
import { ResetChallengeAction } from '~/app/core/state/authentication/authentication.actions';
import { ConfigurationFacade } from '~/app/core/state/configuration/configuration.facade';


export type TurnstileOptions = {
    sitekey: string,
    action?: string,
    cData?: string,
    callback?: (token: string) => void,
    'error-callback'?: (error: Error) => void,
    'expired-callback'?: () => void,
    theme?: 'light' | 'dark' | 'auto',
    'refresh-expired'?: 'auto' | 'manual',
    tabindex?: number,
    appearance?: 'always' | 'execute' | 'interaction-only',
}

declare global {
    interface Window {
        onloadTurnstileCallback: () => void,
        turnstile: {
            render: (
                idOrContainer: string | HTMLElement,
                options: TurnstileOptions
            ) => string,
            reset: (widgetIdOrContainer: string | HTMLElement) => void,
            getResponse: (
                widgetIdOrContainer: string | HTMLElement
            ) => string | undefined,
            remove: (widgetIdOrContainer: string | HTMLElement) => void,
        },
    }
}

const CALLBACK_NAME = 'onloadTurnstileCallback';
type SupportedVersion = '0';

@UntilDestroy()
@Component({
    selector: 'eb-turnstile',
    templateUrl: './turnstile.component.html',
    styleUrls: ['./turnstile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class TurnstileComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() action?: string;

    @Input() cData?: string;

    @Input() theme?: 'light' | 'dark' | 'auto' = 'auto';

    @Input() version: SupportedVersion = '0';

    @Input() tabIndex?: number;

    @Input() appearance?: 'always' | 'execute' | 'interaction-only' = 'always';

    @Output() resolved = new EventEmitter<string | null>();

    @Output() challengeFailure = new EventEmitter<Error | null>();

    @ViewChild('turnstile')
    public turnstile!: ElementRef<HTMLElement>;

    private widgetId!: string;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private zone: NgZone,
        private actions$: Actions,
        private configurationFacade: ConfigurationFacade,
    ) {}

    public ngOnInit(): void {
        this.actions$.pipe(
            ofActionSuccessful(ResetChallengeAction),
            map(() => this.reset()),
            untilDestroyed(this),
        ).subscribe();
    }

    public ngAfterViewInit(): void {
        const turnstileSiteKey = this.configurationFacade.getChallengeSiteKeySnapshot();
        if (!turnstileSiteKey && this.configurationFacade.getChallengeRequiredSnapshot()) {
            throw new Error('Challenge sitekey required');
        }
        if (turnstileSiteKey) {
            const turnstileOptions: TurnstileOptions = {
                sitekey: turnstileSiteKey,
                theme: this.theme,
                tabindex: this.tabIndex,
                action: this.action,
                cData: this.cData,
                appearance: this.appearance,
                'refresh-expired': 'manual',
                callback: (challenge: string) => {
                    this.resolved.emit(challenge);
                },
                'expired-callback': () => {
                    this.zone.run(() => this.reset());
                },
                'error-callback': (error: Error) => {
                    this.zone.run(() => this.challengeFailure.emit(error));
                },
            };

            const script = document.createElement('script');

            window[CALLBACK_NAME] = () => {
                if (!this.elementRef?.nativeElement) {
                    return;
                }

                this.widgetId = window.turnstile.render(
                    this.elementRef.nativeElement,
                    turnstileOptions,
                );
            };

            script.src = `${this.getCloudflareTurnstileUrl()}?render=explicit&onload=${CALLBACK_NAME}`;
            script.async = true;
            script.defer = true;
            document.head.appendChild(script);
        }
    }

    public reset(): void {
        if (this.widgetId) {
            window.turnstile.reset(this.widgetId);
        }
    }

    public ngOnDestroy(): void {
        if (this.widgetId) {
            window.turnstile.remove(this.widgetId);
        }
    }

    private getCloudflareTurnstileUrl(): string {
        if (this.version === '0') {
            return TURNSTILE_SOURCE;
        }
        throw new Error('Version not defined in ngx-turnstile component.');
    }
}
