<div class="end-freetrial__header">
    <eb-h2 class="m-0 mb-2">
        {{ 'try_free_trial.end' | transloco }}
    </eb-h2>
</div>
@if (this.ref.data.context?.featureExtraInfo) {
    <eb-alert
        [type]="'info'"
        size="sm"
        weight="normal"
    >
        {{ this.ref.data.context?.featureExtraInfo | transloco }}
    </eb-alert>
}
<div class="end-freetrial__body">
    @if (error) {
        <eb-alert
            type="error"
            class="mb-3 w-100"
            [text]="errorMsg"
            size="small"
        />
    }
    @if (success) {
        <eb-alert
            type="success"
            class="mb-3 w-100"
            [text]="'try_free_trial.ask_sales_success' | transloco"
            size="small"
        />
    }

    <eb-label
        class="text-center"
        size="lg"
    >
        {{ 'try_free_trial.ask_sales' | transloco }}
    </eb-label>
</div>
<div class="end-freetrial__footer">
    @if (!success) {
        <button
            eb-button
            [disabled]="loading"
            [isLoading]="loading"
            [outline]="false"
            (click)="contactMe()"
            color="secondary"
            size="small"
        >
            {{ 'try_free_trial.contact_me' | transloco }}
        </button>
    }
</div>
