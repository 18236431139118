import {
    HttpClient,
    HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import {
    catchError,
} from 'rxjs/operators';

import { TranslationResponse } from '~/app/shared/types/translation/translation-response.type';

@Injectable({
    providedIn: 'root',
})
export class TranslationService {
    constructor(private httpClient: HttpClient) { }

    public getTranslations(language: string) {
        const params = new HttpParams()
            .set('languages', language);
        return this.httpClient.get<TranslationResponse>(
            '/tenants/configurations/translations',
            {
                params,
            },
        ).pipe(
            catchError(() => of({} as TranslationResponse)),
        );
    }
}
